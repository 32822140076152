/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        // Variables --------------------------
        var $body = $('body');

        // hover menu ---------------
        $('.menu li.dropdown').hover(function() {
          $(this).find('.dropdown-menu').stop(true, true).delay(0).fadeIn(250);
        }, function() {
          $(this).find('.dropdown-menu').stop(true, true).delay(250).fadeOut(250);
        });

        // Menu responsive ---------------
        var $openMenu  = $('.open_menu'),
            $closeMenu = $('.close_menu');

        $openMenu.on('click touch', function() {
          console.log("click open");
          $body.toggleClass('show-menu');
        });

        $closeMenu.on('click touch', function() {
          console.log("click close");
          $body.removeClass('show-menu');
        });

        // testimonios slider -----------
        $(".testimonios_slider").slick({
          dots: true,
          arrows: false,
          infinite: true,
          speed: 300,
          slidesToShow: 2,
          slidesToScroll: 1,
          customPaging : function() {
            return '<div class="custom"></div>';
          },
          autoplay: true,
          autoplaySpeed: 3000,
          responsive: [{
              breakpoint: 992,
              settings: {
                slidesToShow: 1,
              }
            }]
        });

        function slickResize() {
          if ($(window).width() < 768) {
            $(".prensa").slick({
              dots: true,
              arrows: false,
              infinite: true,
              speed: 300,
              slidesToShow: 1,
              slidesToScroll: 1,
              customPaging : function() {
                return '<div class="custom"></div>';
              },
              autoplay: true,
              autoplaySpeed: 3000
            });
          }
        }

        $(window).on('resize', function(){
          slickResize();
        });

        slickResize();

        // SVG Imate to code -------------
        function svg(){
          $('img.svg').each(function(){
            var $img = $(this);
            var imgID = $img.attr('id');
            var imgClass = $img.attr('class');
            var imgURL = $img.attr('src');
            $.get(imgURL, function(data) {
              var $svg = $(data).find('svg');
              if(typeof imgID !== 'undefined') {
                  $svg = $svg.attr('id', imgID);
              }
              if(typeof imgClass !== 'undefined') {
                  $svg = $svg.attr('class', imgClass+' replaced-svg');
              }
              $svg = $svg.removeAttr('xmlns:a');
              if(!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
                  $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'));
              }
              $img.replaceWith($svg);
            }, 'xml');
          });
        }

        $( window ).load(function() {
          svg();
        });

        // Preguntas frecuentes ---------------
        $(function(){
          if ($body.hasClass("preguntas-sobre-empresa")) {
            $('.tab_empresa').addClass('select');
          }
          if ($body.hasClass("preguntas-sobre-inversionista")) {
            $('.tab_inversionista').addClass('select');
          }
        });

        var $preguntas = $('.preguntas'),
            $preguntaSingle = $preguntas.find('.pregunta'),
            $respuestas = $('.respuestas'),
            $preguntaCurrent = $preguntas.find('.current'),
            $currentId = $preguntaCurrent.attr('id');

        $respuestas.find('.content').hide();
        $respuestas.find('.' + $currentId).show();

        $preguntaSingle.hover(function() {
          $preguntaSingle.removeClass('current');
          $( this ).addClass('current');
          $respuestas.find('.content').hide();
          var $thisCurrent = $(this).attr('id');
          $respuestas.find('.' + $thisCurrent).show();
        });

        // Smooth scroll, hash, id ----------------
        $(document).on('click', 'a[href^="#"]', function (event) {
            event.preventDefault();
            $('html, body').animate({
                scrollTop: $($.attr(this, 'href')).offset().top - 88
            }, 500);
        });


        // Mensage de cierre de chile ---------------
        // var body = $('body'),
        //     closeAlert = $('.close_alert');

        // // Revisar cookie y correr codigo
        // window.onload = function () {
        //   if (localStorage.getItem("alertChile")) {
        //     setTimeout(function(){ body.addClass('show_alert_static'); }, 3000);
        //   }
        //   if (localStorage.getItem("alertChile") === null) {
        //     body.addClass('show_alert');
        //     localStorage.setItem("alertChile", true);
        //   }
        // };

        // closeAlert.on('click touch', function() {
        //   body.removeClass('show_alert');
        // });

        
      },
      finalize: function() {
        // Parse the URL
        function getParameterByName(name, url) {
          if (!url) url = window.location.href;
          name = name.replace(/[\[\]]/g, '\\$&');
          var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
          if (!results) return null;
          if (!results[2]) return '';
          return decodeURIComponent(results[2].replace(/\+/g, ' '));
        }
        // Give the URL parameters variable names
        var source = getParameterByName('utm_source');
        var medium = getParameterByName('utm_medium');
        var campaign = getParameterByName('utm_campaign');
        // Set the cookies
        if(source && source !== "") {
          Cookies.set('utm_source', source, { expires: 30 });
        }
        if(medium && medium !== "") {
          Cookies.set('utm_medium', medium, { expires: 30 });
        }
        if(campaign && campaign !== "") {
          Cookies.set('utm_campaign', campaign, { expires: 30 });
        }
        $(document).ready(function() {
          var cookies = {
            'utm_source': Cookies.get('utm_source'),
            'utm_medium': Cookies.get('utm_medium'),
            'utm_campaign': Cookies.get('utm_campaign')
          };
          var cookiesValues = [];
          for (var i in cookies) {
            if (cookies[i]) {
              cookiesValues.push(i + '=' + cookies[i]);
            }
          }
          if (cookiesValues.length > 0) {
            var queryString = cookiesValues.join('&');
            $('a.app-anchor').each(function () {
              var href = $(this).attr('href');
              $(this).attr('href', href + '?' + queryString);
            });
          }
        });
      }
    },
    // Empresa page
    'empresa': {
      init: function() {
        console.log("Estás en empresa");
        // JavaScript to be fired on the empresa page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

        var $body = $('body'),
            $openSimulator  = $('#open_simulator'),
            $closeSimulator = $('#close_simulator'),
            $bgSimulator = $('#simulator_bg');

        $(function(){
          if(window.location.hash === '#cotizador') {
            $body.addClass('show_simulator');
            $body.removeClass('result_simulator');
            resetErrors();
          }
        });

        function hide_simulator() {
          $body.removeClass('show_simulator');
          $body.removeClass('result_simulator');
          remove_hash_from_url();
        }
        function show_simulator() {
          $body.addClass('show_simulator');
          $body.removeClass('result_simulator');
          resetErrors();
          modifyState();
        }

        function modifyState() { 
            const state = { 'page_id': 1, }
            window.history.replaceState(state, 
                "Cotizador web", "#cotizador"); 
        } 

        function remove_hash_from_url() { 
            var uri = window.location.toString(); 
            if (uri.indexOf("#") > 0) { 
              var clean_uri = uri.substring(0,  
                              uri.indexOf("#")); 
              window.history.replaceState({},  
                      document.title, clean_uri); 
            }
        } 

        $openSimulator.on('click touch', show_simulator);
        $bgSimulator.on('click touch', hide_simulator);
        $closeSimulator.on('click touch', hide_simulator);


        function changeCurrencySymbol() {
            var currency = $("#currency").val();
            if (currency === 'USD') {
                $("#soles-icon").remove();
                $("#currency-container").append("<span id='dollar-icon'>$</span>");
            } else {
                $("#dollar-icon").remove();
                $("#currency-container").append("<span id='soles-icon'>S/.</span>");
            }
        }
        function isEmail(email) {
            var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
            return regex.test(email);
        }

        function isPhone(phone) {
            var regex = /^.{8,12}$/;
            return regex.test(phone);
        }

        function isValidRUC(ruc) {
            var regex = /^[0-9]{11}$/;
            return regex.test(ruc);
        }
        function meetLowLimits(amount) {
          var currency = $("#currency").val();
          if ((amount >= 1000 && currency == 'USD') || (amount >= 3000 && currency == 'PEN')) {
              return true;
          } else {
              return false;
          }
        }
        function meetHighLimits(amount) {
          if (amount <= 9999999999) {
              return true;
          } else {
              return false;
          }
        }
        function isValidDays(days) {
            days = parseInt(days);
            if (days > 30 && days < 365) {
                return true;
            } else {
                return false;
            }
        }
        function validateForm() {
            $(".error-message").css("visibility", "hidden");
            $("#low-amount-error").css("display", "none");
            $("#high-amount-error").css("display", "none");
            var showSecondModal = true;
            if ($("#terms").is(':checked') === false) {
                $('#terms-error').css("visibility", "visible");
                showSecondModal = false;
            }
            if (!isEmail($('#email').val())) {
                $('#invalid-email-error').css("visibility", "visible");
                showSecondModal = false;
            }
            if (!isValidRUC($('#debtor-ruc').val())) {
                $('#debtor-ruc-error').css("visibility", "visible");
                showSecondModal = false;
            }
            if (!isValidRUC($('#ruc').val())) {
                $('#ruc-error').css("visibility", "visible");
                showSecondModal = false;
            }
            if ($('#amount').val().replace(/,/g , "") === '') {
                $('#amount-error').css("visibility", "visible");
                showSecondModal = false;
            } else if (!meetLowLimits($('#amount').val().replace(/,/g , ""))){
                $('#amount-error').css("visibility", "visible");
                $('#low-amount-error').css("display", "initial");
                showSecondModal = false;
            } else if (!meetHighLimits($('#amount').val().replace(/,/g , ""))) {
                $('#amount-error').css("visibility", "visible");
                $('#high-amount-error').css("display", "initial");
                showSecondModal = false;
            }
            if ($("input[name=days]:checked").val() === undefined && !isValidDays($('#other-days').val())) {
                $('#days-error').css("visibility", "visible");
                showSecondModal = false;
            }
            if ($('#phone').val() != '' && !isPhone($('#phone').val())) {
                $('#invalid-phone').css("visibility", "visible");
                showSecondModal = false;
            }
            return showSecondModal;
        }
        var isSent = false;
        function revalidateForm() {
            if (isSent) {
                validateForm();
            }
        }
        function addCommas(nStr) {
            nStr += '';
            var x = nStr.split('.');
            var x1 = x[0];
            var x2 = x.length > 1 ? '.' + x[1] : '';
            var rgx = /(\d+)(\d{3})/;
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, '$1' + ',' + '$2');
            }
            return x1 + x2;
        }
        function createQuote() {
            var showSecondModal = validateForm();
            isSent = true;
            if (showSecondModal) {
                jQuery.noConflict();

                // Cedente
                var ruc = $('#ruc').val();
                var email = $('#email').val();
                var phone = $('#phone').val();

                var amount = $("#amount").val().replace(/,/g , "");
                var currency = $("#currency").val();
                var days = $("input[name=days]:checked").val() === undefined ? $('#other-days').val() : $("input[name=days]:checked").val();
                var debtorRuc = $('#debtor-ruc').val();

                var data = {
                    'ruc': ruc,
                    'amount': amount,
                    'currency': currency,
                    'country_code': 'PE',
                    'days': days,
                    'tax_number': debtorRuc,
                    'email': email,
                    'phone': phone,
                    'with_pascal': true
                };

                // var url = 'https://fact2-api-testing.herokuapp.com/v2/quote/operation';
                var url = 'https://fact2-api-prod.herokuapp.com/v2/quote/operation';
                $.post(
                    url,
                    data,
                    function() {
                        $body.addClass('result_simulator');
                        $('.result-col-amount > .currency').html(currency);
                        $('.result-col-amount > .value').html(addCommas(amount));
                        $('.result-col-days > span').html(days + ' días');
                        $('.result-col-ruc > span').html(debtorRuc);
                        $('.result-col-email > span').html(email);
                    },
                    "json"
                ).fail(function() {
                    console.log('request fail');
                });
            }
        }

        function selectOtherDays() {
            $('input:radio[name=days]').each(function () { $(this).prop('checked', false); });
        }

        function resetErrors() {
            $(".error-message").css("visibility", "hidden");
            $("#low-amount-error").css("display", "none");
            $("#high-amount-error").css("display", "none");
        }
        
        $('#amount').mask('000,000,000,000,000', {reverse: true});
        $('.result-col-amount > .value').mask('000,000,000,000,000', {reverse: true});
        $("#currency").on('change', changeCurrencySymbol);
        /*    $("#register-button").on('click', closeModals);*/
        $("#other-days").on('click', selectOtherDays);
        // $("#arrow-button").on('click', resetErrors);
        $("#amount").on('input', revalidateForm);
        $("#debtor-ruc").on('input', revalidateForm);
        $("#ruc").on('input', revalidateForm);
        $("#phone").on('input', revalidateForm);
        $("#email").on('input', revalidateForm);
        $("#other-days").on('input', revalidateForm);
        $("#other-days").on('click', revalidateForm);
        $("input[name=days]").on('change', revalidateForm);
        $("#terms").on('change', revalidateForm);

        $('#30days, #60days, #90days').on('click', function(){
          $("#other-days").val("");
        });

        $("#quote-button").on('click', createQuote);
        $("#requote-button").on('click', function(){
          $body.removeClass('result_simulator');
        });

      }
    },
    // -------------------------------------------
    // -------------------------------------------
    // -------------------------------------------
    // -------------------------------------------
    // Inversionista page
    'inversionista': {
      init: function() {
        console.log("Estás en inversionista");
        // $(".intro_inversionista .simulador .input .input-group .input-group-postpend .input-group-text select").css("background", "none");
        
        var margin = {top: 30, right: 20, bottom: 70, left: 90},
            width = 600 - margin.left - margin.right,
            height = 400 - margin.top - margin.bottom;
        
        var svg = d3.select("#chart")
            .append("svg")
            .attr("class", "svg-chart")
            .attr("width",  width  + margin.left + margin.right)
            .attr("height", height + margin.top  + margin.bottom*(3/2));
          
        var canvas = svg
            .append("g")
              .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

        function simulate() {
          d3.select(".svg-chart")
            .remove();
          d3.selectAll(".d3-tip")
            .remove();
          
          var svg = d3.select("#chart")
          .append("svg")
          .attr("class", "svg-chart")
          .attr("width",  width  + margin.left + margin.right)
          .attr("height", height + margin.top  + margin.bottom*(3/2));
        
          var canvas = svg
          .append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

          var amount = $("#cost-simulator-amount").val().replace(/,/g , "");
          var period = $("#period").val();
          var currency = $("#currency").val();

          // Países
          // Para Chile el currency solo es CLP
          // var country = "CL";
          var country = "PE";
          // var country = "MX";

          if(country == "PE") {
            var monthlyFacturedoProfit = (amount*(20.70/100))/12;
            var monthlyBanksProfit = (amount*(2.46/100))/12;
            var monthlyRentsProfit = (amount*(9.29/100))/12;
            var monthlyFundsProfit = (amount*(12.82/100))/12;
            var simulatorData = [
              {
                'x': theme_directory+'/dist/images/Icon/calculadora/Icon-Banco.svg',
                'y': (parseFloat(amount)+monthlyBanksProfit*period),
                'name': 'Bancos'
              },
              {
                'x': theme_directory+'/dist/images/Icon/calculadora/Icon-Renta.svg',
                'y': (parseFloat(amount)+monthlyRentsProfit*period),
                'name': 'Rentas mixtas'
              },
              {
                'x': theme_directory+'/dist/images/Icon/calculadora/Icon-Fondos.svg',
                'y': (parseFloat(amount)+monthlyFundsProfit*period),
                'name': 'Fondos mutuos'
              },
              {
                'x': theme_directory+'/dist/images/Icon/calculadora/Icon-Facturedo.svg',
                'y': (parseFloat(amount)+monthlyFacturedoProfit*period),
                'name': 'Facturedo'
              }
            ];
          }
          else if(country == "MX") {
            var monthlyFacturedoProfit = (amount*(19.42/100))/12;
            var monthlyBanksProfit = (amount*(4.78/100))/12;
            var monthlyRentsProfit = (amount*(5.91/100))/12;
            var monthlyFundsProfit = (amount*(8.21/100))/12;
            var simulatorData = [
              {
                'x': theme_directory+'/dist/images/Icon/calculadora/Icon-Banco.svg',
                'y': (parseFloat(amount)+monthlyBanksProfit*period),
                'name': 'Bancos'
              },
              {
                'x': theme_directory+'/dist/images/Icon/calculadora/Icon-Renta.svg',
                'y': (parseFloat(amount)+monthlyRentsProfit*period),
                'name': 'AFORE'
              },
              {
                'x': theme_directory+'/dist/images/Icon/calculadora/Icon-Fondos.svg',
                'y': (parseFloat(amount)+monthlyFundsProfit*period),
                'name': 'CETES'
              },
              {
                'x': theme_directory+'/dist/images/Icon/calculadora/Icon-Facturedo.svg',
                'y': (parseFloat(amount)+monthlyFacturedoProfit*period),
                'name': 'Facturedo'
              }
            ];
          }
          else if(country == "CL") {
            var monthlyFacturedoProfit = (amount*(13.35/100))/12;
            var monthlyBanksProfit = (amount*(3.17/100))/12;
            var monthlyRentsProfit = (amount*(5.43/100))/12;
            var monthlyFundsProfit = (amount*(5.54/100))/12;
            var simulatorData = [
              {
                'x': theme_directory+'/dist/images/Icon/calculadora/Icon-Banco.svg',
                'y': (parseFloat(amount)+monthlyBanksProfit*period),
                'name': 'Depósito a plazo'
              },
              {
                'x': theme_directory+'/dist/images/Icon/calculadora/Icon-Renta.svg',
                'y': (parseFloat(amount)+monthlyRentsProfit*period),
                'name': 'AFP (Fondo tipo B)'
              },
              {
                'x': theme_directory+'/dist/images/Icon/calculadora/Icon-Fondos.svg',
                'y': (parseFloat(amount)+monthlyFundsProfit*period),
                'name': 'Fondos mutuos'
              },
              {
                'x': theme_directory+'/dist/images/Icon/calculadora/Icon-Facturedo.svg',
                'y': (parseFloat(amount)+monthlyFacturedoProfit*period),
                'name': 'Facturedo'
              }
            ];
          }

          if ((amount >= 100.00 && currency == 'USD') || (amount >= 300.00 && currency == 'PEN') || (amount >= 2000.00 && currency == 'MXN') || (amount >= 70000.00 && currency == 'CLP')) {
          
            var xScaleIcon = d3.scale.ordinal()
              .domain(simulatorData.map(function(d) { return d.x; }))
              .rangeRoundBands([0, width-(margin.right*2)], 0.3, 0.2);

            var yScale = d3.scale.linear()
              // .domain([0,d3.max(simulatorData, function(d) { return d.y; })])
              .domain([d3.min(simulatorData, function(d) { return d.y; })-(d3.min(simulatorData, function(d) { return d.y; })/60),d3.max(simulatorData, function(d) { return d.y; })])
              .range([height, 0 + margin.top ]);

            var formatCurrency = d3.format(",.2f");

            var tip = d3.tip()
              .attr('class', 'd3-tip')
              .offset([+5, 0])
              .html(function(d) {
                return "$" + formatCurrency(parseFloat((d.y)).toFixed(2)) + " <span>" + currency + "</span>";
              });
            
            var iconTip = d3.tip()
              .attr('class', 'd3-tip')
              .offset([-20, 0])
              .html(function(d) {
                return d.name;
              });
            
            canvas.call(tip);
            canvas.call(iconTip);

            var bars = canvas.selectAll(".bar")
              .data(simulatorData);
            
            bars
              .enter()
              .append("rect")
              .attr("class", function(d,i) {
                if(i<3) {
                  return "bar";
                }
                else {
                  return "bar fact-bar";
                }
              })
              .attr("width", xScaleIcon.rangeBand())
              .attr("height", 0)
              .attr("x", function(d) { 
                return xScaleIcon(d.x); })
              .attr("y", height)
              .on('mouseover', function(d,i) {
                if(i<3) {
                  tip.show(d);
                }
              })
              .on('mouseout', function(d,i) {
                if(i<3) {
                  tip.hide(d);
                }
              });
            
            bars
              .transition().duration(450)
              .attr("y",function(d) { return yScale(d.y); })
              .attr("height",function(d) { return height - yScale(d.y); });
              
            var textFactBar = svg.append("text")
              .attr("x", margin.left*5+25)             
              .attr("y", margin.top)
              .attr('class', "fixed-tooltip d3-tip n")
              .attr("text-anchor", "middle")  
              .style("font-size", "16px")
              .text("$")
              .append("tspan")
              .text(formatCurrency(parseFloat((simulatorData[3].y)).toFixed(2)))
              .append("tspan")
              .text(" " + currency);
                    
            // var textLength = textFactBar.node().getComputedTextLength();
            
            // svg.append("rect")
            //   .attr("x", function() {
            //     // if(textLength == 0) {
            //     //   return margin.left*7;
            //     // }
            //     // else {
            //     //   return textFactBar.attr("x")-(textLength/2)-11;
            //     // }
            //     console.log(textLength);
            //     return 400;
            //   })             
            //   .attr("y", function() {
            //     return margin.top-24;
            //   })
            //   .attr("width", function() {
            //     // if(textLength == 0) {
            //     //   return 113;
            //     // }
            //     // else {
            //     //   return textLength+24;
            //     // }
            //     return textLength+24;
            //   })
            //   .attr("class", "d3-tip n")
            //   .attr("height", 40)
            //   .attr("text-anchor", "middle")  
            //   .style("stroke", "#a0999991")
            //   .style("fill", "none")
            //   .style("stroke-width", "1")
            //   .style("fill-opacity", "1")
            //   .style("stroke-opacity", "1");
            
            // var arc = d3.svg.symbol().type('triangle-down')
            //   .size(40);
            // svg.append("path")
            //   .attr("d", arc)
            //   .attr('transform',function(){ return "translate("+textFactBar.attr("x")+","+(54) +")"; });

            var xAxisIcon = d3.svg.axis()
              .scale(xScaleIcon)
              .orient("bottom");

            var yAxis = d3.svg.axis()
              .scale(yScale)
              .orient("left")
              .ticks(6);

            var xAxisIconSection = svg.append("g")
              .attr("transform", "translate(" + margin.left + "," + (height+margin.top) + ")")
              .attr("class","x axis icon-axis")
              .call(xAxisIcon);
            
            var yAxisSection = svg.append("g")
              .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
              .attr("class", "y axis")
              .call(yAxis);

            yAxisSection
              .append("text")
              .attr("class", "axisLabel")
              .attr("y", 4)
              .attr("dy", ".71em")
              .style("text-anchor", "end")
              .text("$");
            
            var IconTicks = d3.select(".icon-axis").selectAll(".tick");
                IconTicks.selectAll("text").remove();
                IconTicks.data(simulatorData)
                  .append("svg:image")
                  .attr("xlink:href", function(d) { 
                    return d.x;
                  })
                  .attr("width", 28)
                  .attr("height", 28)
                  .attr("x", -10)
                  .attr("y", 10);
            

            d3.selectAll('.icon-axis .tick')
              .data(simulatorData)
              .on('mouseover', function(d,i) {
                iconTip.show(d);  
              })
              .on('mouseout', function(d,i) {
                iconTip.hide(d);  
              });

            var finalText = svg.append("text")
              .attr("class", "finalText")
              .attr("x", margin.left*3+20)             
              .attr("y", width-(margin.top*2+10))
              .attr("text-anchor", "middle")  
              .style("font-size", "15px")
              .attr("font-weight", 400)
              .text("Invierte ")
              .append("tspan")
              .attr("font-weight", 500)
              .text(formatCurrency(parseFloat((amount)).toFixed(2)))
              .append("tspan")
              .attr("font-weight", 400)
              .text(" en Facturedo y en ")
              .append("tspan")
              .attr("font-weight", 500)
              .text(period + " meses ")
              .append("tspan")
              .attr("font-weight", 400)
              .text("tendrás $")
              .append("tspan")
              .attr("font-weight", 500)
              .text(formatCurrency(parseFloat((simulatorData[3].y)).toFixed(2)))
              .append("tspan")
              .attr("font-weight", 400)
              .text(" "+currency);

            $('.result-chart').show();
          }
          else {
            $('.result-chart').hide();
            return false;
          }
        }

        $('#cost-simulator-amount').mask('000,000,000,000,000', {reverse: true});
        $("#cost-simulator-amount").on('input', simulate);
        // $("#currency").on('change', simulate);
        $("#currency").on('change', function(event){
          event.preventDefault();
          simulate();

          if (this.value === "USD") {
            $("#cost-simulator-amount").attr("placeholder", "Min. 100USD");
            $("#inversion_minima").text('(Min. 100USD)');
          } else if (this.value === "PEN") {
            $("#cost-simulator-amount").attr("placeholder", "Min. 330PEN");
            $("#inversion_minima").text('(Min. 330PEN)');
          }
        });
        $("#period").on('change', simulate);
        $('.result-chart').hide();
      }
    },
    'libro_de_reclamaciones': {
      init: function() {
        console.log("Libro de reclamaciones");
        autosize($('textarea'));
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
